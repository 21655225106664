import { node } from '@/plugins/axios'

export const create = async ({ request, email }) => {
    try {
      const { data } = await node.post('/autocheck', request)
      const uuid = data?.autocheck?.response?.uuid
  
      const response = JSON.parse(data?.autocheck?.response?.response)
      const capToken = response?.valuationData?.token
  
      if (capToken) sendCapEmail({ capToken, email })
  
      return { uuid }
    } catch (err) {
      throw err
    }
  }

  export const sendCapEmail = async params => {
    try {
      await node.post('/autocheck/cap', params)
    } catch (err) {
      throw err
    }
  }

  export const buildPreDescription = response => {
    if (!response) return 'Vehicle description unavailable'

    const values = []
    const props = ['year', 'makeModel', 'engineCapacity', 'bhp']

    if (!response.makeModel && response.make) {
      const model = [response.make, response.model, response.trim].filter(Boolean).join(' ')
      response.makeModel = model || 'Unknown model'
    }

    props.forEach(prop => {
      const value = response[prop]
      if (value) {
        try {
          if (prop === 'bhp') {
            const bhpValue = parseInt(value)
            if (!isNaN(bhpValue)) {
              values.push(`${bhpValue}BHP`)
            }
          } else if (prop === 'engineCapacity') {
            const capacityValue = parseFloat(value)
            if (!isNaN(capacityValue)) {
              values.push(capacityValue.toFixed(1))
            }
          } else {
            values.push(value.toString())
          }
        } catch (err) {
          throw err
        }
      }
    })

    return values.length ? values.join(' ') : 'Vehicle description unavailable'
  }
  
  export const precheck = async ({ value, type, mileage }) => {
    const params = {
      [type]: value
    }
  
    if (mileage) {
      params.mileage = mileage
    }

    try {
      const { data } = await node.get('/precheck', { params })
      return data
    } catch (err) {
      throw err
    }
  }